import { Title } from '@solidjs/meta';
import {
	Button,
	Errors,
	Field,
	FieldErrors,
	Heading,
	Input,
	Label,
	Option,
	Select,
	TextField,
	Container,
	Page,
	BreadcrumbItem,
} from '@troon/ui';
import { Formspree, formspreeAction, useFormspree } from '@troon/forms';
import { createSignal, Match, Show, Switch } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { NoHydration } from 'solid-js/web';
import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { getConfigValue } from '../../../modules/config';
import { useUser } from '../../../providers/user';
import { Hero } from '../../../components/hero/photo';
import type { RouteDefinition } from '@solidjs/router';

export default function SupplierDiversificationPage() {
	const [requireOther, setRequireOther] = createSignal(false);
	const user = useUser();
	const formAction = useFormspree(action);
	const submission = useSubmission(formAction);

	return (
		<>
			<Title>Troon Supplier Diversification Initiative | Diversity, Equity, and Inclusion | Troon</Title>

			<NoHydration>
				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/web/hero/about.jpg`}
					crop="center"
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
							<BreadcrumbItem href="/about/dei">DEI</BreadcrumbItem>
							<BreadcrumbItem href="/about/dei/supplier-diversification-initiative">
								Supplier Diversification Initiative
							</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1" class="text-center text-white">
						Supplier Diversification Initiative
					</Heading>
				</Hero>
			</NoHydration>

			<Container size="small">
				<Page>
					<Switch>
						<Match when={submission.result}>
							<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
								<IconCircleCheckCustom class="text-8xl" />
								<Heading as="h1" size="h2">
									Request received!
								</Heading>
								<p class="text-lg">Thanks for contacting us! We will get in touch with you shortly.</p>
							</div>
						</Match>
						<Match when>
							<Formspree action={formAction}>
								<Heading as="h2" class="sr-only">
									Supplier Diversification consideration form
								</Heading>
								<TextField name="Brand name" required>
									<Label>What is your brand name?</Label>
									<Input autocomplete="organization" />
								</TextField>

								<TextField name="Company website" required>
									<Label>What is your company’s website</Label>
									<Input type="url" inputMode="url" autocomplete="url" />
								</TextField>

								<Field name="Product category" required>
									<Label>What category best describes your products and/or services</Label>
									<Select>
										<Option disabled selected class="hidden" />
										<Option>Golf Operations</Option>
										<Option>Retail</Option>
										<Option>Fitness</Option>
										<Option>Agronomy</Option>
										<Option>Food & Beverage</Option>
										<Option>FF&E</Option>
										<Option>Maintenance</Option>
									</Select>
								</Field>

								<Field name="Owner identification" required>
									<Label>Is your company owned by the following:</Label>
									<Select
										onChange={(event) => {
											setRequireOther(event.currentTarget.value === 'Other');
										}}
									>
										<Option disabled selected class="hidden" />
										<Option>Asian / Pacific Islander</Option>
										<Option>Black</Option>
										<Option>Service Disabled / Veteran</Option>
										<Option>Hispanic / Latino American</Option>
										<Option>LGBT+</Option>
										<Option>Native American / Native Indian</Option>
										<Option>Women</Option>
										<Option>Other</Option>
									</Select>
									<FieldErrors />
								</Field>

								<Show when={requireOther()}>
									<TextField name="Owner identification (other)" required={requireOther()}>
										<Label>If other, please specify</Label>
										<Input />
									</TextField>
								</Show>

								<TextField name="Annual sales volume" required>
									<Label>What is your company’s annual sales volume?</Label>
									<Input />
								</TextField>

								<TextField name="Business tenure" required>
									<Label>How long has your company been in business?</Label>
									<Input />
								</TextField>

								<fieldset class="flex flex-col gap-4">
									<legend class="text-xl font-semibold">Primary contact information</legend>

									<div class="flex gap-4">
										<TextField name="First name" required class="grow">
											<Label>First name</Label>
											<Input autocomplete="given-name" value={user()?.me.firstName} />
										</TextField>

										<TextField name="Last name" required class="grow">
											<Label>Last name</Label>
											<Input autocomplete="family-name" value={user()?.me.lastName} />
										</TextField>
									</div>

									<TextField name="Title">
										<Label>Title</Label>
										<Input />
									</TextField>

									<TextField name="email" required>
										<Label>Email address</Label>
										<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
									</TextField>

									<TextField name="Phone number">
										<Label>Phone number</Label>
										<Input autocomplete="tel" inputMode="tel" />
									</TextField>
								</fieldset>

								<Errors />

								<Button type="submit" class="w-fit">
									Submit
								</Button>
							</Formspree>
						</Match>
					</Switch>
				</Page>
			</Container>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'supplier-diversification');

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
